<template>
  <div id="app-base-file">
    <div class="header">
      <div class="header-action">
        <div class="min-box" v-if="this.$ipc">
          <div class="min" @click="min(1)"></div>
        </div>
        <div class="min-box" v-if="this.$ipc">
          <div class="max" @click="min(3)"></div>
        </div>
        <div class="min-box-right" v-if="this.$ipc">
          <div class="close-right" @click="min(2)"></div>
        </div>
      </div>
      <div class="title">
        <div class="title-text">{{ fromName }} <img v-show="fromNameZ" width="15px" height="15px" src="@/assets/zicon.png" /></div>
        <div class="more" @click="cheoosInfos"></div>
      </div>
    </div>
    <div style="height: calc(100% - 75px); width: 100%; display: flex">
      <div style="height: 100%; width: 100%; position: relative">
        <div class="message" id="message" ref="mianscroll" @scroll="orderscrollEvent">
          <ul v-if="menuVisible" class="context-menu" :style="menuStyle">
            <li v-if="pressItemTo.messageType == 'txt'" @click="menuItemClicked('copy')"><image src="https://cdn.binliyoujia.com/static/message/copy-pc.png"></image>复制</li>
            <li v-if="pressItemTo.messageType != 'audio'" @click="menuItemClicked('forward')"><image src="https://cdn.binliyoujia.com/static/message/forward-pc.png"></image>转发</li>
            <li @click="menuItemClicked('quote')"><image src="https://cdn.binliyoujia.com/static/message/quote-pc.png"></image>引用</li>
            <li @click="menuItemClicked('select')"><image src="https://cdn.binliyoujia.com/static/message/select-pc.png"></image>多选</li>
            <li v-if="getIsGoBack()" @click="menuItemClicked('back')"><image src="https://cdn.binliyoujia.com/static/message/back-pc.png"></image>撤回</li>
            <li @click="menuItemClicked('addimg')"><image src="https://cdn.binliyoujia.com/static/message/addimg-pc.png"></image>添加至参考图库</li>
          </ul>
          <div class="message-box" v-for="item in MessageList" :key="'message' + item.id" :id="'message' + item.id"  @click.stop="setMessageItem(item)">
            <!-- 消息 -->
              <messageBodyList :item="item" @setValues="setValues" @openMenu="openMenu" @openDemand="openDemand" :isMore="isMore" @setForwardItem="setForwardItem" @openForwardMessageListDailogByList="openForwardMessageListDailogByList" @goToQuoteMessage="goToQuoteMessage"></messageBodyList>
          </div>
          <a-spin v-if="islond" />
        </div>
        <!-- 转发扩展弹窗 -->
        <div class="forward-plant" v-if="showForwrad">
          <div class="forward-item" @click="openForwardDailog(0)">
            <img class="z-forward-pc" src="https://cdn.binliyoujia.com/static/message/z-forward-pc.png" />
            <div class="forward-txt">逐条转发</div>
          </div>
          <div class="forward-item" @click="openForwardDailog(1)">
            <img class="h-forward-pc" src="https://cdn.binliyoujia.com/static/message/h-forward-pc.png" />
            <div class="forward-txt">合并转发</div>
          </div>
          <div class="forward-item">
            <img class="down-pc" src="https://cdn.binliyoujia.com/static/message/down-pc.png" />
            <div class="forward-txt" style="color: #eee">批量下载</div>
          </div>
          <div class="forward-item" @click="closeForward">
            <img class="close-pc" src="https://cdn.binliyoujia.com/static/message/close-pc.png" />
          </div>
        </div>

        <!-- 引用扩展弹窗 -->
        <div class="quote-plant" v-if="showQuote" :style="quoteStyle">
          <div class="quote-text">
            <div>{{ getMessageFromMemberName() }}:</div>
            <div style="display: flex; font-weight: 500;" v-if="pressItem.messageType == 'txt'">{{ pressItem.messageMsg }}</div>
            <div style="display: flex; align-items:center;" v-if="pressItem.messageType == 'audio'">[语音]{{ pressItem.messageAudioLength }}"</div>
            <div style="display: flex; align-items:center" v-if="pressItem.messageType == 'img'"><img style="max-height: 56px;margin-right:10px;" :src="pressItem.messageUrl" /></div>
            <div style="display: flex; align-items:center" v-if="pressItem.messageType == 'file'"><img style="max-height: 32px; width: 32rpx;margin-right:10px;" :src="getimage(pressItem.messageFileName)" />{{ pressItem.messageFileName }}</div>
            <div style="display: flex; align-items:center" v-if="pressItem.messageType == 'video'">[视频]<img style="max-height: 56px;margin-right:10px;" :src="pressItem.videoUrl" /></div>
            <div style="display: flex; align-items:center" v-if="pressItem.messageType == 'custom'">[订单消息]</div>
          </div>
          <div @click="closequote">
            <img style="width: 16px; height: 16px" src="https://cdn.binliyoujia.com/static/message/quote-close.png" />
          </div>
        </div>
        <!-- 输入面板 -->
        <div class="intext-tuo" id="intextTuo" @mousedown="down" @mouseup="up" v-if="!showForwrad">
          <div class="intext" @mousedown.stop="" @mouseup.stop="up">
            <div class="intext-action">
              <div class="left-action">
                <div class="bq" @click="openEmo"></div>
                <div class="tp" @click="cheoosImage"></div>
                <div class="wj" @click="cheoosfile"></div>
                <div class="ls" @click="cheoosHistory"></div>
                <div class="yy" @click="openViode"></div>
                <div class="sp" @click="SendViode"></div>
              </div>
              <div class="right-action">
                <div style="display: flex; cursor: pointer">
                  <div @click="openOrders" class="yqtext" style="display: flex">
                    <div class="yq"></div>
                    <div>邀请下单</div>
                  </div>
                  <div class="order" @click="cheoosExts"></div>
                </div>
              </div>
            </div>
            <div class="inputbox">
              <textarea ref="editable" @paste="onPasteImage" @keyup.enter="sendTextMessage" onkeydown="if (event.keyCode === 13) event.preventDefault();" v-model="textContent" class="chat_content_editable" spellcheck="false" contenteditable="true" placeholder="请输入消息内容..."> </textarea>
              <button class="send-button" @click="sendTextMessage">发送</button>
            </div>
          </div>
        </div>

        <!-- 表情扩展栏 -->
        <a-modal title="" style="bottom: 20px" v-model="emoVisible" :closable="false" :dialogStyle="dialogStyle" :footer="null" width="274px" height="274px">
          <div class="emobox">
            <div v-for="(item, index) in emojis" :key="index" class="emoitem" @click="addOneEmoji(item)">
              <img class="emoitemimg" :src="getEmimgF(item)" />
            </div>
          </div>
        </a-modal>

        <!-- 文件上传组件 -->
        <input ref="uploadImgs" type="file" multiple style="display: none" single accept="image/*" @change="sendImagesMessage('common')" />
        <input ref="uploadFiles" type="file" style="display: none" single @change="sendFilesMessages" />
        <input ref="uploadVideo" type="file" style="display: none" single @change="sendVideoMessages" />

        <!-- 录音框 -->
        <CollectAudio v-if="isShowRecordBox" @sendAudioMessages="sendAudioMessages" @backAudio="backAudio" />
      </div>

      <!-- 图片预览框 -->
      <a-modal title="" class="imgdialog" style="bottom: 20px" v-model="imgVisible" :closable="false" :footer="null" width="960px" height="640px">
        <div class="imgheader">
          <div class="imgtitle">图片预览</div>
          <div class="imgtitleaction">
            <div class="close" style="margin-top: 5px; margin-right: 0px" @click="minimg()"></div>
          </div>
        </div>
        <div class="imgbox">
          <img :src="preimgurl" draggable="true" :style="{ transform: 'scale(' + multiples + ')' }" @dragstart="dragstart($event)" @dragend="dragend($event)" />
        </div>
        <div class="imgaction">
          <a href="#" style="margin-left: 10px" @click="sximg"><img src="@/assets/suoxiao.png" /></a>
          <a href="#" style="margin-left: 10px" @click="pfimg"><img src="@/assets/fangda.png" /></a>
          <a :href="preimgurl" style="margin-left: 10px" download><img src="@/assets/xiazai.png" /></a>
        </div>
      </a-modal>
      

      
      <!-- 转发详情列表 -->
      <a-modal title="" class="forward-message-list" :body-style="forwardbBody2" style="bottom: 20px" v-model="forwardMessageListDailog" :closable="false" :footer="null" width="840px" height="570px">
        <div class="f-m-title">
          <div class="f-m-t-text">{{ forwardMessageTitle }}</div>
          <img @click="closeForwardMessageListDailog" class="close-pc" src="https://cdn.binliyoujia.com/static/message/close-pc.png" />
        </div>
        <div class="f-m-time">
          {{ consoleTime }}
        </div>
        <div class="f-m-list">
          <div class="f-m-item" v-for="item in dataMessageList" :key="item.id">
            <div class="f-m-i-title">
              <div class="f-m-i-t-text" v-if="item.belong">{{ getMemberName(item) }}</div>
              <div class="f-m-i-t-text" v-if="!item.belong">{{ getMemberName(item) }}</div>
              <div class="f-m-i-t-time">{{ dateFormater(item.createTime) }}</div>
            </div>
            <div class="f-m-i-body">
              <messageBodyListPlant :item="item" @openForwardMessageListDailogByList="openForwardMessageListDailogByList"></messageBodyListPlant>
            </div>
          </div>
        </div>
      </a-modal>

      <!-- 查看需求提示框 -->
      <a-modal title="" v-model="showDemandPlant" style="bottom: 20px" :closable="false" :footer="null" width="540px" height="368px">
        <div class="imgheader-order  demand-body" style="margin-top:0px;">
          <div class="imgtitle-order" style="font-size:16px;font-weight:bold;">需求详情</div>
          <div class="imgtitleaction">
            <div class="close-order" style="margin-top: 16px; margin-right: 0px" @click="closeDemandPlant()"></div>
          </div>
        </div>
        <div class="imgheader-order demand-body" style="height: 368px; overflow: auto;" v-if="demandDetail">
          <div class="demand-item">
              <div class="demand-title">服务类型</div>
              <div class="demand-desc">{{demandDetail.type == 2?'家装':'工装'}}</div>
          </div>
          <div class="demand-item">
              <div class="demand-title">小区名称</div>
              <div class="demand-desc">{{demandDetail.name}}</div>
          </div>
          <div class="demand-item" v-if="demandDetail.type == 2">
              <div class="demand-title">我的房型</div>
              <div class="demand-desc">家装</div>
          </div>
          <div class="demand-item" v-if="demandDetail.type == 2">
              <div class="demand-title">风格偏好</div>
              <div class="demand-desc">家装</div>
          </div>
          <div class="demand-item" v-if="demandDetail.type == 1">
              <div class="demand-title">空间类型</div>
              <div class="demand-desc">家装</div>
          </div>
          <div class="demand-item">
              <div class="demand-title">房屋面积</div>
              <div class="demand-desc">{{demandDetail.area}}㎡</div>
          </div>
          <div class="demand-item">
              <div class="demand-title">期望出图日期</div>
              <div class="demand-desc">{{demandDetail.outPicDate}}</div>
          </div>
          <div class="demand-line"></div>
          <div class="demand-item" style="display: block;" v-if="demandDetail.housePicUrls">
              <div class="demand-desc">户型图</div>
              <div class="demand-image"><img style="width: 100%; margin-top: 16px; border-radius: 8px; border:1px solid #eee;" :src="itemImage" v-for="(itemImage,imgIndex) in demandDetail.housePicUrls.split(',')" :key="imgIndex"/></div>
          </div>
          <div class="demand-item" style="display: block;" v-if="demandDetail.referencePicUrls">
              <div class="demand-desc">参考图</div>
              <div class="demand-image"><img style="width: 100%; margin-top: 16px;" :src="itemImage" v-for="(itemImage,imgIndex) in demandDetail.referencePicUrls.split(',')" :key="imgIndex"/></div>
          </div>
          <div class="demand-item" style="display: block;" v-if="demandDetail.demandPros">
              <div class="demand-desc">需求信息</div>
              <div class="demand-tab">
                  <div @click="setArrList(item)" class="demand-tab-item" :class="demandDetail.houseIndexId == item.id?'active':''" v-for="(item,index) in demandDetail.demandPros" :key="index">{{item.name}}</div>
              </div>
              <div class="demand-body">
                  <div class="demand-body-item" v-for="(item,index) in spaceDetailArrList" :key="index">
                      <div style="color:#666;">{{item.name}}</div>
                      <div v-for="(dma,dmai) in item.dmarr" :key="dmai" style="margin-left:12px;display: flex; align-items: center; color:#222;">{{dma.name}}
                        <div v-for="(sa,sai) in dma.sarr" :key="sai" style="margin-left:12px;display: flex; align-items: center; color:#666;"><img style="width:12px;height: 8px;margin-right:12px;" src="https://cdn.binliyoujia.com/newstatic/images/jt.png"> {{sa.name}}
                          <div v-for="(sma,smai) in sa.smarr" :key="smai" style="margin-left:12px;color:#222;">{{sma.name}}</div>
                        </div>
                      </div>
                  
                  </div>
              </div>
          </div>
          <div class="demand-line"></div>
          <div class="demand-item" v-if="demandDetail.content">
              <div class="demand-title">需求补充</div>
              <div class="demand-desc">{{demandDetail.content}}</div>
          </div>
        </div>
        <div class="right-foot" style="height: 25px; border: none">
          <div class="right-foot-button" @click="sendDemandOrder" >创建需求</div>
        </div>
      </a-modal>


      <!-- 发送图片提示框 -->
      <a-modal title="" v-model="showImagePlant" style="bottom: 20px" :closable="false" :footer="null" width="540px" height="368px">
        <div class="imgheader-order">
          <div class="imgtitle-order">是否发送图片</div>
          <div class="imgtitleaction">
            <div class="close-order" style="margin-top: 16px; margin-right: 0px" @click="closeImagePlant()"></div>
          </div>
        </div>
        <div class="imgheader-order">
          <div style="height: 200px; padding: 20px">
            <img style="height: 100px; width: 100px" :src="fileBase64" />
          </div>
        </div>
        <div class="right-foot" style="height: 25px; border: none">
          <div class="right-foot-button" @click="sendImageSum">发送</div>
        </div>
      </a-modal>

      <!-- 文件发送提示框 -->
      <a-modal title="" v-model="showFilePlant" style="bottom: 20px" :closable="false" :footer="null" width="540px" height="368px">
        <div class="imgheader-order">
          <div class="imgtitle-order">是否发送文件</div>
          <div class="imgtitleaction">
            <div class="close-order" style="margin-top: 16px; margin-right: 0px" @click="closeFilePlant()"></div>
          </div>
        </div>
        <div class="imgheader-order">
          <div style="height: 200px; padding: 20px">
            <div>文件名称：{{ fileObj ? fileObj.name : '' }}</div>
          </div>
        </div>
        <div class="imgheader-order">
          <div class="right-foot-button" @click="sendImageSum">发送</div>
        </div>
      </a-modal>
      <!-- 右侧扩展栏 -->
      <CollectInfos v-if="isShowInfos" class="history" :uid="userId" :chatType="type" @updateInfos="updateInfos" />
      <!-- 右侧扩展栏 -->
      <CollectExts v-if="isShowExts" class="history" :uid="userId" :chatType="type" @updateInfos="updateInfos" @gotoGroup="gotoGroup" @backCy="backCy" @onBackZdy="onBackZdy" @onBackZj="onBackZj" />
      <!-- 历史消息 -->
      <a-modal title="" v-model="isShowHistory" style="bottom: 20px" :closable="false" :footer="null" width="540px" height="368px">
        <CollectHistory v-if="isShowHistory" class="history" style="border: none; width: auto" :uid="userid" :chatType="type" @updateInfos="updateInfos" @closemin="closemin" />
      </a-modal>
      <!-- 邀请下单框 -->
      <a-modal title="" class="imgdialog-order" style="bottom: 20px" v-model="ProructVisible" :closable="false"  :footer="null" width="600px" height="570px">
        <InviteOrder :userId="userId" :type="type" @close="closeInviteOrder" :demandId="demandId"></InviteOrder>
      </a-modal>
      <!-- 转发消息框 -->
      <a-modal title="" class="forward-dailog" :body-style="forwardbBody" style="bottom: 20px" v-model="forwardDailog" :closable="false" :footer="null" width="472px" height="441px">
        <ForwardDailog :userId="userId" :type="type" :forwardList="forwardList" :fromName="fromName" @close="closeForwardDailog" @closeForward="closeForward" @openForwardMessageListDailog="openForwardMessageListDailog" :forwardMessageTitle="forwardMessageTitle" :forwardSendType="forwardSendType" :forwardType="forwardType"></ForwardDailog>
      </a-modal>
      <div v-show="menuVisible" class="mb" @click="closeMb" ></div>
    </div>
  </div>
</template>
<script>
import storage from 'store2'
import { ipcApiRoute, requestHttpGet, requestHttpPostByOnline } from '@/api/main'
import emMap from '@/utils/em'
import { emojis } from '@/constant'
import fileSizeFormat from '@/utils/fileSizeFormat'
import _ from 'lodash'
import CollectAudio from '@/components/audio.vue'
import CollectHistory from '@/components/history.vue'
import CollectInfos from '@/components/infos.vue'
import CollectExts from '@/components/exts.vue'
import CollectPj from '@/components/pj.vue'
import CollectOrder from '@/components/order.vue'
import CollectXq from '@/components/xq.vue'
import CollectFa from '@/components/fa.vue'
import CollectGoods from '@/components/goods.vue'

import previewImage from '@/components/elimage.vue'
import apiUrl from '@/utils/config'
import { message } from 'ant-design-vue'
import dateFormaterSession from '@/utils/dateFormaterSession'
import dateFormater from '@/utils/dateFormater'
import messageBodyList from '@/components/messageBodyList/messageBodyList.vue'
import messageBodyListPlant from '@/components/messageBodyList/messageBodyListPlant.vue'

import InviteOrder from '@/components/dailog/inviteOrder.vue'
import ForwardDailog from '@/components/dailog/forwardDailog.vue'
export default {
  components: {
    CollectAudio,
    CollectHistory,
    CollectExts,
    CollectInfos,
    CollectPj,
    CollectOrder,
    CollectXq,
    CollectFa,
    CollectGoods,
    previewImage,
    InviteOrder,
    ForwardDailog,
    messageBodyList,
    messageBodyListPlant,
  },
  props: {
    uid: {
      type: String,
      default: '',
    },
    pId: {
      type: Number,
      default: 0,
    },
    usId: {
      type: String,
      default: '',
    },
    chatType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileBase64: '',
      showImagePlant: false,
      showFilePlant: false,
      loading: false,
      emMap: emMap,
      fromName: '',
      ProructVisible: false,
      forwardDailog: false,
      islond: false,
      multiples: 1,
      textContent: '',
      dialogMessageValue: '',
      forwardMessageListDailog: false,
      oldY: 0,
      flag: false,
      targetDiv: '',
      targetDivHeight: 0,
      MessageList: [],
      forwardType: 0,
      username: '',
      emoVisible: false,
      showDemandPlant:false,
      forwardbBody: {
        padding: 0,
        display: 'flex',
        borderRadius: '10px',
      },
      forwardbBody2: {
        padding: 0,
        borderRadius: '10px',
      },
      dialogStyle: {
        top: '100px',
        'margin-left': '280px',
      },
      emojis: emojis,
      fileObj: null,
      fileSizeFormat: fileSizeFormat,
      isShowRecordBox: false,
      imgVisible: false,
      preimgurl: '',
      initWidth: 0, // 父元素的宽-自适应值
      initHeight: 0, // 父元素的高-自适应值
      startclientX: 0, // 元素拖拽前距离浏览器的X轴位置
      startclientY: 0, //元素拖拽前距离浏览器的Y轴位置
      elLeft: 0, // 元素的左偏移量
      elTop: 0, // 元素的右偏移量
      isShowHistory: false,
      isShowInfos: false,
      isShowExts: false,
      page: -1,
      loadList: [],
      forwardList: [],
      forwardSendType: 0,
      forwardMessageTitle: '',
      cyy: '',

      fromNameZ: false,
      orderType: 1,
      seachvalue: '',
      productList: [],
      pageNo: 1,
      pageSize: 25,
      itemId: '',
      userId: '',
      pageId: '',
      type: '',
      hasMore: true,
      menuVisible: false,
      menuStyle: {
        top: 0,
        left: 0,
      },
      pressItem: {},
      isMore: false,
      pressItemTo: {},
      showForwrad: false,
      showQuote: false,
      quoteStyle: {
        bottom: 0,
      },
      forwardSearchValue: '',
      consoleTime: '',
      dataMessageList: [],
      demandDetail:{},
      spaceDetailArrList:[],
      demandId:0
    }
  },
  mounted() {
    //关闭监听新消息
    this.$bus.$off('im.allChatMessage')
    this.$bus.$off('im.allGroupMessage')
    this.$bus.$off('im.allChatReadMessage')
    this.$bus.$off('im.allBackMessage')
    //监听新消息
    this.$bus.$on('im.allChatMessage', this.newChatMessage)
    this.$bus.$on('im.allGroupMessage', this.newGroupMessage)
    this.$bus.$on('im.allBackMessage', this.updateBackMessage)
    //已读回执
    this.$bus.$on('im.allChatReadMessage', this.readMessage)
  },
  destroyed() {
    console.log('销毁')
    this.$bus.$off('im.allChatMessage')
    this.$bus.$off('im.allGroupMessage')
    this.$bus.$off('im.allChatReadMessage')
    this.$bus.$off('im.allBackMessage')
  },
  methods: {
    async initdata() {
      console.log('你被调用几次？', this.userId)
      this.userId = this.usId
      this.itemId = this.uid
      this.pageId = this.pId
      this.type = this.chatType
      this.pageNo = 1
      this.MessageList = []
      await this.getHostList()
    },
    closeMb() {
      this.menuVisible = false
    },
    closeInviteOrder(){
      this.ProructVisible = false
    },
    dateFormater(v) {
      return dateFormaterSession(v)
    },
    async newChatMessage(e) {
      let that = this
      console.log('收到单聊消息', e)
      console.log('当前会话', that.itemId)
      //判断是否在当前会话
      if (that.itemId == e.messageSessionId) {
        //添加消息
        let body = await that.$ImSdk.getChatMessageBody(e)
        body.createTime = new Date(body.createTime).getTime()
        that.MessageList.push(body)

        if (storage.get('userId') != e.messageFrom) {
          that.$ImSdk.sendReadMessage(body['id'])
        }
        //设置滚动到最新消息
        that.$nextTick(() => {
          //获取最后加载的最有一个div
          let toItem = document.getElementById('message' + body['id'])
          that.scrollToItem(toItem)
        })
      }
    },
    async newGroupMessage(e) {
      let that = this
      console.log('收到群聊消息', e)
      //判断是否在当前会话
      if (this.itemId == e.messageSessionId) {
        //添加消息
        let body = await this.$ImSdk.getGroupMessageBody(e)
        body.createTime = new Date(body.createTime).getTime()
        that.MessageList.push(body)
        console.log(body)
        //设置滚动到最新消息
        that.$nextTick(() => {
          //获取最后加载的最有一个div
          let toItem = document.getElementById('message' + body['id'])
          that.scrollToItem(toItem)
        })
      }
    },
    async readMessage(e) {
      console.log('收到已读消息回执', e)
      if (this.itemId == e.messageSessionId) {
        //当前会话 设置消息为已读
        this.MessageList.forEach(m => {
          if (m.id == e.id) {
            m.isRead = true
          }
        })
      }
    },
    closeImagePlant() {
      this.showImagePlant = false
    },
    closeFilePlant() {
      this.showFilePlant = false
    },
    closeDemandPlant(){
      this.showDemandPlant = false
    },
    getEmimg(item) {
      if (item.messageMsg.indexOf('[') >= 0) {
        let msgs = item.messageMsg
        //分析出所有表情
        for (const key in this.emMap) {
          if (item.messageMsg.indexOf(key) >= 0) {
            msgs = msgs.replaceAll(key, '<img src="' + require('../assets/faces/' + this.emMap[key]) + '"/>')
          }
        }
        return msgs
      } else {
        return item.messageMsg
      }
    },
    getEmimgF(item) {
      if (item.indexOf('[') >= 0) {
        return 'https://cdn.binliyoujia.com/static/im/faces/' + this.emMap[item]
      } else {
        return item
      }
    },
    sximg() {
      if (this.multiples <= 1) {
        return
      }
      this.multiples -= 0.25
    },
    pfimg() {
      if (this.multiples >= 2) {
        return
      }
      this.multiples += 0.25
    },
    //获取聊天框标题
    async getFromUserName() {
      if (this.type == 'chat') {
        //获取服务器会话信息
        let res = await this.$ImSdk.getSessionsInfo({
          id: this.itemId,
          userId: storage.get('userId'),
        })
        if (res.noteName != null) {
          this.fromName = res.noteName
        } else {
          this.fromName = res.otherMember.nickname
        }
        //获取装修标识
        if (this.userId.indexOf('mb_') >= 0) {
          let resdata = await requestHttpGet(apiUrl + '/admin-api/supplier/user/user-member-info', {
            id: this.userId.split('mb_')[1],
          })
          if (resdata.data.identityType == '1') {
            this.fromNameZ = true
          } else {
            this.fromNameZ = false
          }
        }
      }
      if (this.type == 'group') {
        //获取服务器会话信息
        let res = await this.$ImSdk.getSessionsInfo({
          id: this.itemId,
          userId: storage.get('userId'),
        })
        console.log(res)
        if (res.noteName != null) {
          this.fromName = res.noteName
        } else {
          this.fromName = res.name
        }
        console.log(this.fromName)
      }
    },
    async orderscrollEvent(e) {
      const { scrollTop } = e.target
      if (scrollTop === 0 && this.pageNo != 1) {
        await this.loadMore()
      }
    },
    async loadMore() {
      let that = this
      if (!this.hasMore) return
      let obj = {
        messageSessionId: this.itemId,
        type: this.type,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      let res = await this.$ImSdk.getMessagePage(obj)

      this.MessageList.unshift(...res)
      
      if (res.length <= 0) {
        this.hasMore = false
      } else {
        this.$nextTick(() => {
          //获取最后加载的最有一个div
          let toItem = document.getElementById('message' + res[res.length - 1]['id'])
          that.scrollToItem(toItem)
        })
      }
      this.pageNo++
    },
    scrollToItem(item) {
      let container = this.$refs.mianscroll
      let scrollTop = item.offsetTop + item.offsetHeight
      container.scrollTop = scrollTop
    },

    scrollToItemCenter(item) {
      let container = this.$refs.mianscroll
      let scrollTop = item.offsetTop - 400
      container.scrollTop = scrollTop
    },
    //回调自定义消息
    async onBackZdy(e, type) {
      // 获取自定义消息类型 设计订单  商品订单
      console.log(e)
      let obj = {
        id: e.id, //订单id
        no: e.no, //订单单号
        name: e.items[0].spuName, //商品名称
        image: e.items[0].picUrl, //商品图片
        price: e.payPrice, //订单金额
        status: e.status, //订单状态
        refundStatus: e.refundStatus, //退款状态
        type: type, //自定义消息类型
      }
      let extType = 0
      let ext = {}
      await this.$ImSdk.sendCustomMessages(this.userId, this.type, obj, this, ext, extType)
      setTimeout(() => {
        let scrollEl = document.getElementById('message')
        scrollEl.scrollTo({
          top: scrollEl.scrollHeight + 100,
          behavior: 'smooth',
        })
      }, 50)
    },
    //回自定义足迹消息
    async onBackZj(e, type) {
      // 获取自定义消息类型 效果图  设计商品
      console.log(e)
      // 区分
      let extType = 0
      let ext = {}
      for (let index = 0; index < e.length; index++) {
        const item = e[index]
        let obj = {}
        if (item.type == 4) {
          //设计商品
          obj.id = item.footprintDesignerProduct.id //商品id
          obj.name = item.footprintDesignerProduct.name //商品名称
          obj.image = item.footprintDesignerProduct.picUrl //商品图片
          obj.price = item.footprintDesignerProduct.price
          obj.type = type
        }
        if (item.type == 3) {
          //效果图
          obj.id = item.footprintRender.id //效果图d
          obj.name = item.footprintRender.name //效果图名称
          obj.image = item.footprintRender.coverPicUrls.split(',')[0] //效果图图片
          obj.price = 0
          obj.type = type
        }
        console.log(obj)
        //发送自定义消息

        await this.$ImSdk.sendCustomMessages(this.userId, this.type, obj, this, ext, extType)
      }
      setTimeout(() => {
        let scrollEl = document.getElementById('message')
        scrollEl.scrollTo({
          top: scrollEl.scrollHeight + 100,
          behavior: 'smooth',
        })
      }, 50)
    },
    //设置内容
    backCy(e) {
      this.cyy = e
      this.textContent = e.text
    },
    updateInfos(e) {
      this.$emit('updateInfos', e)
    },
    gotoGroup(e) {
      console.log(e)
      this.$emit('gotoGroup', e)
    },
    closemin() {
      this.isShowInfos = false
      this.isShowExts = false
      this.isShowHistory = false
    },
    dragstart(e) {
      console.log(e)
      this.startclientX = e.clientX // 记录拖拽元素初始位置
      this.startclientY = e.clientY
    },
    // 拖拽完成事件
    dragend(e) {
      console.log(e)
      let x = e.clientX - this.startclientX // 计算偏移量
      let y = e.clientY - this.startclientY

      this.elLeft += x // 实现拖拽元素随偏移量移动
      this.elTop += y
      console.log(this.elLeft)
    },
    setValues(e) {
      this.textContent = e
    },
    getimage(item) {
      let res = require('@/assets/pngfile.png')
      switch (item.split('.')[1]) {
        case 'png':
          res = require('@/assets/pngfile.png')
          break
        case 'doc':
          res = require('@/assets/wfile.png')
          break
        case 'xlsx':
          res = require('@/assets/xfile.png')
          break
        case 'dwg':
          res = require('@/assets/cadfile.png')
          break
        case 'zip':
          res = require('@/assets/zfile.png')
          break
        case 'pptx':
          res = require('@/assets/pfile.png')
          break
        default:
          break
      }
      return res
    },
    openViode() {
      this.isShowRecordBox = true
    },
    backAudio() {
      this.isShowRecordBox = false
    },
    setForwardItem(item) {
      this.MessageList.forEach((e)=>{
        if(e.id == item.id){
          e.isSelect = item.isSelect
        }
      })
      this.$forceUpdate()
    },

    //发送文本消息
    async sendTextMessage() {
      let extType = 0
      let ext = {}
      if (this.showQuote) {
        extType = 1
        ext = this.pressItem
      }
      await this.$ImSdk.sendTextMessage(this.userId, this.type, this.textContent, this, ext, extType)
      this.textContent = ''
      this.showQuote = false
      setTimeout(() => {
        let scrollEl = document.getElementById('message')
        scrollEl.scrollTo({
          top: scrollEl.scrollHeight + 100,
          behavior: 'smooth',
        })
      }, 50)
    },
    async sendFilesMessages() {
      let extType = 0
      let ext = {}
      await this.$ImSdk.sendFileMessage(this.userId, this.type, this.$refs.uploadFiles.files[0], ext, extType)
      setTimeout(() => {
        let scrollEl = document.getElementById('message')
        scrollEl.scrollTo({
          top: scrollEl.scrollHeight + 100,
          behavior: 'smooth',
        })
      }, 50)
      this.$refs.uploadFiles.value = null
    },
    async sendImagesMessage(type) {
      this.islond = true
      let extType = 0
      let ext = {}
      if (type == 'common') {
        console.log(this.$refs.uploadImgs.files)
        for (let index = 0; index < this.$refs.uploadImgs.files.length; index++) {
          const fileinfo = this.$refs.uploadImgs.files[index]
          await this.$ImSdk.sendImagesMessage(this.userId, this.type, fileinfo, ext, extType)
          this.$refs.uploadImgs.value = null
        }
      } else if (type == 'other') {
        await this.$ImSdk.sendImagesMessage(this.userId, this.type, this.fileObj, ext, extType)
        this.fileObj = null
      }
      this.islond = false
    },
    async sendVideoMessages() {
      let extType = 0
      let ext = {}
      await this.$ImSdk.sendVideoMessage(this.userId, this.type, this.$refs.uploadVideo.files[0], ext, extType)
      setTimeout(() => {
        let scrollEl = document.getElementById('message')
        scrollEl.scrollTo({
          top: scrollEl.scrollHeight + 100,
          behavior: 'smooth',
        })
      }, 50)
      this.isShowRecordBox = false
    },
    async sendAudioMessages(audioData) {
      let extType = 0
      let ext = {}
      await this.$ImSdk.sendAudioMessage(this.userId, this.type, audioData, ext, extType)
      setTimeout(() => {
        let scrollEl = document.getElementById('message')
        scrollEl.scrollTo({
          top: scrollEl.scrollHeight + 100,
          behavior: 'smooth',
        })
      }, 50)
      this.isShowRecordBox = false
    },
    onPasteImage(event) {
      let that = this
      console.log('>>>>>>监听粘贴事件', event)
      const data = event.clipboardData || window.clipboardData
      //获取图片内容
      const imgContent = data.items[0]
      console.log(imgContent)
      console.log(imgContent.getAsFile())
      const reader = new FileReader()
      if (imgContent.kind === 'file' && imgContent.type.startsWith('image/')) {
        //图片
        that.fileObj = imgContent.getAsFile()
        reader.readAsDataURL(imgContent.getAsFile())
        reader.onload = event => {
          //获取base64流
          that.fileBase64 = event.target.result
          console.log('发送图片', that.fileBase64)
          that.sendImagePlant()
        }
      }
      if (imgContent.kind === 'file' && !imgContent.type.startsWith('image/')) {
        //文件
        console.log('发送文件')
        that.fileObj = imgContent.getAsFile()
        that.sendFilePlant()
      }
    },
    sendImagePlant() {
      this.showImagePlant = true
    },
    sendFilePlant() {
      this.showFilePlant = true
    },
    sendImageSum() {
      this.sendImagesMessage('other')
      this.showImagePlant = false
      this.showFilePlant = false
    },
    sendDemandOrder(){
      this.ProructVisible = true
    },
    openOrders() {
      this.ProructVisible = true
      this.demandId = 0
    },
    openDir(id) {
      if (this.$ipc) {
        this.$ipc.invoke(ipcApiRoute.openFile, { id: id }).then(result => {
          console.log(result)
        })
      }
    },
    cheoosImage() {
      this.$refs.uploadImgs.click()
    },
    cheoosfile() {
      this.$refs.uploadFiles.click()
    },
    SendViode() {
      this.$refs.uploadVideo.click()
    },
    cheoosHistory() {
      this.isShowInfos = false
      this.isShowExts = false
      this.isShowHistory = !this.isShowHistory
    },
    cheoosInfos() {
      if (this.type == 'group') {
        this.isShowHistory = false
        this.isShowExts = false
        this.isShowInfos = !this.isShowInfos
      }
    },
    cheoosExts() {
      if (this.type != 'group') {
        this.isShowInfos = false
        this.isShowHistory = false
        this.isShowExts = !this.isShowExts
      } else if (this.type == 'group') {
        this.isShowInfos = false
        this.isShowHistory = false
        this.isShowExts = !this.isShowExts
      }
    },
    addOneEmoji(emoji) {
      this.textContent = this.textContent + emoji
      this.emoVisible = !this.emoVisible
      // this.sendTextMessage()
    },
    openEmo() {
      this.dialogStyle.top = document.getElementById('message').offsetHeight - 62 + 'px'
      this.emoVisible = !this.emoVisible
    },
    //初始化历史消息
    async getHostList() {
      console.log('获取会话消息', this.itemId)
      //类型错误返回
      if (!this.type) {
        return
      }
      this.MessageList = []
      //会话id错误返回
      if (!this.itemId) {
        console.log(this.itemId)
        console.log('无会话消息')
        return
      }
      //获取头部信息
      await this.getFromUserName()
      //获取消息
      console.log(this.type)
      this.hasMore = true
      this.pageNo = 1
      await this.loadMore()
    },
    down(e) {
      this.flag = true
      this.oldY = e.pageY //记录鼠标按下位置
      this.targetDiv = document.getElementById('intextTuo')
      this.targetDivHeight = this.targetDiv.offsetHeight
      this.targetTopDiv = document.getElementById('message')
      this.targetTopDivHeight = this.targetTopDiv.offsetHeight
      this.startTd(e)
    },
    startTd(ev) {
      let that = this
      let height = 0
      let moveY = 0
      this.flag = true
      window.addEventListener('mousemove', e => {
        if (that.flag) {
          moveY = e.pageY //检测鼠标移动位置
          height = this.oldY - moveY
          if (height > 0) {
            if (this.targetDivHeight + Math.abs(height) <= 400) {
              document.getElementById('intextTuo').style.height = this.targetDivHeight + Math.abs(height) + 'px'
              document.getElementById('message').style.height = this.targetTopDivHeight - Math.abs(height) + 'px'
            }
          } else {
            if (this.targetDivHeight - Math.abs(height) >= 122) {
              document.getElementById('intextTuo').style.height = this.targetDivHeight - Math.abs(height) + 'px'
              document.getElementById('message').style.height = this.targetTopDivHeight + Math.abs(height) + 'px'
            }
          }
          that.$nextTick(() => {
            //获取最后加载的最有一个div
            let toItem = document.getElementById('message' + this.MessageList[this.MessageList.length - 1]['id'])
            that.scrollToItem(toItem)
          })
        }
      })

      window.addEventListener('mouseup', e => {
        console.log('鼠标抬起')
        that.flag = false
      })
    },
    up(e) {
      this.flag = false
    },
    min(type) {
      this.$ipc.invoke(ipcApiRoute.action, type).then(result => {
        console.log(result)
      })
    },
    minimg() {
      this.imgVisible = false
    },
    getMessageFromMemberName() {
      let txtName = ''
      this.pressItem.members.forEach(e => {
        if (this.pressItem.messageFrom == e.id) {
          txtName = e.nickname
        }
      })
      return txtName
    },
    openMenu(e) {
      let event = e.event
      let item = e.item
      console.log("右键事件打开层")
      this.pressItemTo = item
      event.preventDefault()
      var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      var wheight = document.getElementById('intextTuo') ? document.getElementById('intextTuo').offsetHeight : 122
      // 显示自定义菜单
      this.menuVisible = true
      
      // 设置菜单的位置
      if (width - 250 > event.clientX) {
        this.menuStyle.left = `${event.clientX}px`
      } else {
        this.menuStyle.left = `${width - 250}px`
      }
      if (height - wheight - 190 > event.clientY) {
        this.menuStyle.top = `${event.clientY}px`
      } else {
        this.menuStyle.top = `${height - wheight - 190}px`
      }
      this.$forceUpdate()
      console.log(this.menuStyle)
    },
    async openDemand(id){
        console.log(id)
        let res = await requestHttpGet(apiUrl + '/app-api/member/demand/get', { id: id })
        this.demandDetail = res.data
        
        if(this.demandDetail.demandPros){
          this.demandDetail.demandPros = JSON.parse(this.demandDetail.demandPros)
          this.setArrList(this.demandDetail.demandPros[0])
        }
        this.showDemandPlant = true
        this.demandId = id
    },
    menuItemClicked(action) {
      // 处理菜单项的点击事件
      console.log(action)
      console.log(this.pressItemTo)
      this.isMore = false
      this.showForwrad = false
      this.showQuote = false
      // 可以根据action执行不同的操作
      if (action == 'copy') {
        let oInput = document.createElement('input')
        // 将想要复制的值
        oInput.value = this.pressItemTo.messageMsg
        // 页面底部追加输入框
        document.body.appendChild(oInput)
        // 选中输入框
        oInput.select()
        // 执行浏览器复制命令
        document.execCommand('Copy')
        // 弹出复制成功信息
        oInput.remove()
        message.info('复制成功!')
      }
      if (action == 'forward') {
        this.showForwrad = true
        this.pressItem = this.pressItemTo
        this.forwardType = 1 //单条转发
      }
      if (action == 'quote') {
        this.showQuote = true
        this.quoteStyle.bottom = '122px'
        this.pressItem = this.pressItemTo
      }
      if (action == 'select') {
        this.isMore = true
        this.showForwrad = true
        this.forwardType = 2 //多条转发
      }
      if (action == 'back') {
        this.pressItem = this.pressItemTo
        this.backMessage(this.pressItem)
      }
      // if(action == 'addimg'){

      // }
      // 最后隐藏菜单
      this.menuVisible = false
    },
    openForwardDailog(v) {
      console.log("打开转发扩展弹窗")
      this.forwardList = []
      if (this.forwardType == 1) {
        //单条
        this.forwardList.push(this.pressItem)
      }
      if (this.forwardType == 2) {
        //多条
        this.MessageList.forEach(e => {
          if (e.isSelect) {
            this.forwardList.push(e)
          }
        })
      }
      if (this.forwardList.length <= 0) {
        return
      }
      if (this.forwardList.length == 1) {
        this.forwardMessageTitle = this.forwardList[0].messageMsg
      }
      if (this.forwardList.length > 1) {
        if (this.type == 'group') {
          this.forwardMessageTitle = this.fromName + '群聊的聊天记录'
        }
        if (this.type == 'chat') {
          this.forwardMessageTitle = storage.get('UserName') + '与' + this.fromName + '的聊天记录'
        }
      }
      this.forwardSendType = v
      if (this.forwardSendType == 0) {
        this.forwardMessageTitle = '[逐条转发]' + this.forwardMessageTitle
      }
      if (this.forwardSendType == 1) {
        this.forwardMessageTitle = '[合并转发]' + this.forwardMessageTitle
      }
      console.log("打开转发扩展弹窗",this.forwardList)
      this.forwardDailog = true
    },
    
    closeForwardDailog() {
      this.forwardDailog = false
    },
    closeForward() {
      this.showForwrad = false
      this.isMore = false
      this.MessageList.forEach(e => {
        e.isSelect = false
      })
      this.$forceUpdate()
    },
    closequote() {
      this.showQuote = false
    },
    getIsGoBack() {
      console.log("条件渲染")
      console.log(this.pressItemTo)
      let sTime = this.pressItemTo.createTime
      let eTime = new Date().getTime()
      console.log(this.pressItemTo)
      console.log(eTime - sTime < 120000 && this.pressItemTo.belong)
      if (eTime - sTime < 120000 && this.pressItemTo.belong) {
        return true
      }
      return false
    },
    getExtType(item) {
      return JSON.parse(item.messageExt).messageType
    },
    getExtName(item) {
      let nameText = ''
      let ms = JSON.parse(item.messageExt)
      ms.members.forEach(e => {
        if (ms.messageFrom == e.id) {
          nameText = e.nickname
        }
      })
      return nameText
    },

    getExtCustom(item) {
      let ms = JSON.parse(item.messageExt)
      return this.getCustomTitle(ms)
    },
    getInfoCustom(item) {
      return this.getCustomTitle(item)
    },
    getCustomTitle(e) {
      if (e.messageCustomExt.type == 'designerOrder') {
        if (e.messageCustomExt.status == 100) {
          return '订单已关闭'
        }
        if (e.messageCustomExt.status == 0) {
          return '请您确认支付'
        }
        if (e.messageCustomExt.status == 10) {
          return '已支付'
        }
        if (e.messageCustomExt.status == 20) {
          return '请您确认需求'
        }
        if (e.messageCustomExt.status == 30) {
          return '制图中'
        }
        if (e.messageCustomExt.status == 40) {
          return '请您确认图纸'
        }
        if (e.messageCustomExt.status == 50) {
          return '已完成'
        }
      }
      if (e.messageCustomExt.type == 'selectOrder') {
        return '商品价格改好了，可直接付款哦'
      }
      if (e.messageCustomExt.type == 'demandOrder') {
        return '我发送了一个设计需求，请尽快查看并回复哦~'
      }
      if (e.messageCustomExt.type == 'designerFixOrder') {
        return '请您支付补差金额'
      }
      if (e.messageCustomExt.type == 'renderProduct') {
        return '效果图'
      }

      if (e.messageCustomExt.type == 'submitChangeSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'demandSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'paySuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundOrder') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundChangeOrder') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundOrderSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'refundChangeOrderSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'subimtSuccess') {
        return e.messageCustomExt.title
      }
      if (e.messageCustomExt.type == 'designerChangeOrder') {
        if (e.messageCustomExt.status == 60) {
          return '请您确认修改需求'
        }
        if (e.messageCustomExt.status == 70) {
          return '改图制图中'
        }
        if (e.messageCustomExt.status == 50) {
          return '已完成'
        }
        if (e.messageCustomExt.status == 0) {
          return '请您确认修改需求'
        }
      }
    },
    getExtMsg(item) {
      let msg = JSON.parse(item.messageExt).messageMsg
      if (msg.indexOf('[') >= 0) {
        let msgs = msg
        //分析出所有表情
        for (const key in emMap) {
          if (msg.indexOf(key) >= 0) {
            for (var i = 0; i < 20; i++) {
              msgs = msgs.replace(key, '<image style="width:18px;height:18px;" src="' + ('https://cdn.binliyoujia.com/static/im/faces/' + emMap[key]) + '"/>')
            }
          }
        }
        return msgs
      } else {
        return msg
      }
    },
    getMemberName(item) {
      let nameText = ''
      item.members.forEach(e => {
        if (item.messageFrom == e.id) {
          nameText = e.nickname
        }
      })
      return nameText
    },
    getExtImg(item) {
      let ms = JSON.parse(item.messageExt)
      return ms.messageUrl
    },
    getExtFileName(item) {
      let ms = JSON.parse(item.messageExt)
      return ms.messageFileName
    },

    getExtAudioLength(item) {
      let ms = JSON.parse(item.messageExt)
      return ms.messageAudioLength
    },
    getExtVideoUrl(item) {
      let ms = JSON.parse(item.messageExt)
      return ms.videoUrl
    },
    searchHanlde(v) {
      if (this.forwardSearchValue == '') {
        this.sessionList.forEach(e => {
          e.isShow = true
        })
      }
      this.sessionList.forEach(e => {
        if (e.type == 'chat') {
          let str = e.otherMember.nickname
          if (str.indexOf(this.forwardSearchValue) >= 0) {
            e.isShow = true
          } else {
            e.isShow = false
          }
        }
        if (e.type == 'group') {
          let str = e.noteName ? e.noteName : e.name
          if (str.indexOf(this.forwardSearchValue) >= 0) {
            e.isShow = true
          } else {
            e.isShow = false
          }
        }
      })
    },
    getExtFileIcon(item) {
      let ms = JSON.parse(item.messageExt)
      let imageUrl = ''
      console.log(ms.messageFileName)
      let fileExtension = ms.messageFileName.substring(ms.messageFileName.lastIndexOf('.') + 1)
      if (fileExtension == 'zip' || fileExtension == 'ZIP') {
        imageUrl = 'zip'
      }
      if (fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'DOC' || fileExtension == 'DOCX') {
        imageUrl = 'word'
      }
      if (fileExtension == 'xls' || fileExtension == 'xlsx' || fileExtension == 'XLS' || fileExtension == 'XLSX') {
        imageUrl = 'xls'
      }
      if (fileExtension == 'txt' || fileExtension == 'TXT') {
        imageUrl = 'txt'
      }
      if (fileExtension == 'pdf' || fileExtension == 'PDF') {
        imageUrl = 'pdf'
      }
      if (fileExtension == 'ppt' || fileExtension == 'pptx' || fileExtension == 'PPT' || fileExtension == 'PPTX') {
        imageUrl = 'ppt'
      }
      if (fileExtension == 'dwg' || fileExtension == 'dxf' || fileExtension == 'DWG' || fileExtension == 'DXF') {
        imageUrl = 'cad'
      }
      return 'https://cdn.binliyoujia.com/static/message/message-img/' + imageUrl + '.png'
    },
    setMessageItem(item) {
      if (item.messageType == 'audio') {
        return
      }
      item.isSelect = !item.isSelect
      this.$forceUpdate()
    },
    goToQuoteMessage(item){
      let that = this
      console.log(item.messageExt.id)
      that.$nextTick(() => {
            //获取最后加载的最有一个div
          let toItem = document.getElementById('message' + item.messageExt.id)
          that.scrollToItemCenter(toItem)
        })
    },
    //消息列表打开转发消息详情
    openForwardMessageListDailogByList(item) {
      this.dataMessageList =item.messageExt
      this.forwardMessageTitle = item.messageMsg
      let timeStart = []
      this.dataMessageList.forEach(e => {
        let timeText = dateFormater('YYYY-MM-DD', e.createTime)
        if (!timeStart.includes(timeText)) {
          timeStart.push(timeText)
        }
        e.textTime = dateFormater('MM/DD HH:mm:ss', e.createTime)
      })
      if (timeStart.length > 1) {
        timeStart.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        this.consoleTime = timeStart[0] + '~' + timeStart[timeStart.length - 1]
      } else {
        this.consoleTime = timeStart[0]
      }
      this.forwardMessageListDailog = true
    },

    //转发窗体打开转发消息详情
    openForwardMessageListDailog() {
      this.dataMessageList = this.forwardList
      let timeStart = []
      this.dataMessageList.forEach(e => {
        let timeText = dateFormater('YYYY-MM-DD', e.createTime)
        if (!timeStart.includes(timeText)) {
          timeStart.push(timeText)
        }
        e.textTime = dateFormater('MM/DD HH:mm:ss', e.createTime)
      })
      if (timeStart.length > 1) {
        timeStart.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        this.consoleTime = timeStart[0] + '~' + timeStart[timeStart.length - 1]
      } else {
        this.consoleTime = timeStart[0]
      }
      this.forwardMessageListDailog = true
    },
    closeForwardMessageListDailog() {
      this.forwardMessageListDailog = false
    },

    //获取扩展消息
    getExtMessages(item) {
      let forwardArr = []
      forwardArr = JSON.parse(item.messageExt).exts
      console.log(forwardArr)
      return forwardArr
    },
    //撤回消息动作
    async backMessage() {
      let messageId = this.pressItem.id
      let res = await requestHttpGet(apiUrl + '/app-api/netty/im-message/back-message', { id: messageId })
      if (res.data) {
        message.info('撤回成功!')
      }
    },
    setArrList(item){
        this.demandDetail.houseIndexId = item.id
        this.spaceDetailArrList = []
        item.darr.forEach(e=>{
					this.spaceDetailArrList.push(e)
				})
        // this.spaceDetailArrList.forEach(e=>{
				// 	e.child = []
				// 	e.sarr.forEach(a=>{
				// 		a.smarr.forEach(m=>{
				// 			e.child.push(m)
				// 		})
				// 	})
				// })
        console.log(this.spaceDetailArrList)
        this.$forceUpdate()
    },
    //撤回消息事件
    updateBackMessage(e) {
      console.log('收到撤回消息通知', e)
      if (this.itemId == e.messageSessionId) {
        //当前会话 设置消息撤回
        this.MessageList.forEach(m => {
          if (m.id == e.id) {
            m.status = 2
          }
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
page {
  font-family: 'PingFang SC';
}
.red {
  width: 10px;
  height: 10px;
  background-image: url('~@/assets/red1.png');
  background-size: 100%;
  margin-left: 10px;
  align-self: center;
  margin-top: 20px;
}
.red2 {
  width: 10px;
  height: 10px;
  background-image: url('~@/assets/red2.png');
  background-size: 100%;
  margin-left: 10px;
  align-self: center;
  margin-top: 20px;
}

.message-time {
  text-align: left;
  margin-left: 42px;
  margin-top: 6px;
  font-family: PingFangSC-Regular;
  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
}

.message-time-my {
  text-align: right;
  margin-right: 65px;
  margin-top: 6px;
  font-family: PingFangSC-Regular;
  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
}

.message-box {
  margin-top: 22px;
  margin-left: 22px;
  user-select: text;
  margin-bottom: 20px;
}

#app-base-file {
  width: 100%;
  background-color: #fff;
  height: 100%;
  position: relative;
}

.inputbox {
  min-height: calc(100% - 50px);
  position: relative;
}

.chat_content_editable {
  font-family: 'PingFang SC';
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  border: none;
  background: none;
  letter-spacing: 0.5px;
  resize: none;
  padding: 0px 22px;
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
}

textarea {
  outline: none;
}

.intext-action {
  height: 37px;
  display: flex;
  justify-content: space-between;
  margin-left: 23px;
  margin-right: 23px;
}

.left-action {
  height: 37px;
  display: flex;
  justify-content: flex-start;
}

.bq {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/bq.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.tp {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/tp.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.wj {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/wj.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.ls {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/ls.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.yy {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/yy.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.sp {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/shipin.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 18px;
  cursor: pointer;
}

.yq {
  width: 14px;
  height: 14px;
  background-image: url('~@/assets/yqxd.png');
  background-size: 100%;
  margin-top: 10px;
  margin-right: 8px;
  cursor: pointer;
}

.order {
  width: 17px;
  height: 17px;
  background-image: url('~@/assets/order.png');
  background-size: 100%;
  margin-top: 10px;
  cursor: pointer;
}

.message {
  width: 100%;
  height: calc(100% - 125px);
  background-color: #fff;
  overflow-y: auto;
}
.message::-webkit-scrollbar {
  background-color: #fff;
  width: 3px;
}
.message::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background: #eee;
}
.intext-tuo {
  height: 122px;
  opacity: 1;
  background: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  cursor: s-resize;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.intext-tuo:hover {
  cursor: s-resize;
}

.intext {
  height: calc(100% - 2px);
  margin-top: 1px;
}

.intext:hover {
  cursor: default;
}

.header {
  width: 100%;
  height: 73px;
  // background-color: blanchedalmond;
  -webkit-app-region: drag;
  -webkit-user-select: none;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px 0px #eeeeee;
}

.header-action {
  height: 21px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.min {
  width: 11px;
  height: 11px;
  background-image: url('~@/assets/main-min.png');
  background-size: 100%;
  background-position: center;
  margin-top: 5px;
  -webkit-app-region: no-drag;
  margin-left: 5px;
}

.max {
  width: 11px;
  height: 11px;
  background-image: url('~@/assets/main-max.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5px;
  margin-left: 5px;
  -webkit-app-region: no-drag;
}

.close {
  width: 11px;
  height: 11px;
  background-image: url('~@/assets/main-close.png');
  background-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  -webkit-app-region: no-drag;
}
.close-order {
  width: 18px;
  height: 18px;
  background-image: url('~@/assets/main-close.png');
  background-size: 100%;
  margin-top: 16px;
  margin-left: 5px;
  -webkit-app-region: no-drag;
}
.close-right {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/main-close.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-app-region: no-drag;
}
.close-right:hover {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/main-close-w.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  -webkit-app-region: no-drag;
}
.min-box {
  width: 21px;
  cursor: pointer;
}
.min-box:hover {
  width: 21px;
  background-color: #eeeeee;
}
.min-box-right {
  width: 21px;
  margin-right: 10px;
  cursor: pointer;
}
.min-box-right:hover {
  width: 21px;
  margin-right: 10px;
  background-color: #ed2616;
}
.more {
  width: 17px;
  height: 3px;
  background-image: url('~@/assets/main-more.png');
  background-size: 100%;
  margin-top: 10px;
  -webkit-app-region: no-drag;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.title {
  height: 23px;

  display: flex;
  justify-content: space-between;
  margin-right: 16px;
  margin-left: 22px;
  margin-top: 7px;
}

.title-text {
  font-family: PingFangSC-Medium;
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0px;
  color: #222222;
}

.send-button {
  position: absolute;
  right: 23px;
  bottom: 0px;
  width: 56px;
  height: 25px;
  border-radius: 4px;
  opacity: 1;
  background: #f7f7f7;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  cursor: pointer;
}

.emobox {
  width: 100%;
  height: 105px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.emobox::-webkit-scrollbar {
  width: 0 !important;
}

.emoitem {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-align: center;
  line-height: 25px;
  transition: all 0.3s ease;
  margin-right: 1px;
  margin-left: 0.5px;
  &:hover {
    transform: scale(1.2);
  }
}
.emoitemimg {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.image-box {
  width: 100px;
}

.audio-text {
  font-family: PingFangSCRegular-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #999999;
  margin-left: 8px;
}

.audio-text-my {
  font-family: PingFangSCRegular-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #999999;
  margin-left: 8px;
}

.imgheader {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  position: relative;
}
/deep/ .ant-modal-body {
  padding: 16px;
}
.imgbox {
  display: flex;
  justify-content: center;
  height: 640px;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.imgbox img {
  max-width: 80%;
  max-height: 640px;
  position: absolute;
  z-index: 10;
  user-select: none;
}
.imgaction {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  margin: auto;
}
.imgtitleaction {
  position: absolute;
  right: 16px;
  top: 0px;
}
.history {
  width: 260px;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #dddddd;
  flex-shrink: 0;
}
.custom-body {
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
  padding: 8px;
  border-radius: 5px 5px 5px 5px;
  background-color: #fff;
}
.custom-img {
  width: 60px;
  height: 60px;
}
.custom-img img {
  width: 60px;
  height: 60px;
  border-radius: 5px 5px 5px 5px;
}
.custom-text {
  height: 60px;
  width: 180px;
  margin-left: 8px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.custom-name {
  width: 100px; /* 设置容器的宽度 */
  white-space: nowrap; /* 禁止文本换行 */
  overflow: hidden; /* 隐藏超出部分的内容 */
  text-overflow: ellipsis; /* 当文本溢出时显示省略号 */
}
.custom-action {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.custom-cel {
  border-radius: 20px;
  height: 29px;
  width: 100px;
  box-sizing: border-box;
}
.custom-ok {
  border-radius: 20px;
  height: 29px;
  font-size: 12px;
  opacity: 1;
  background: linear-gradient(90deg, #ff6044 0%, #ed2616 100%);
  box-shadow: 0px 8px 8px 0px rgba(237, 38, 22, 0.1);
  border: none;
  color: #fff;
}
.custom-ok-cleal {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #999;
}
.custom-ok-cleal:hover {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #999;
  color: #ed2616 !important;
}
.custom-ok-cleal:active {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #999;
  color: #ed2616;
}
.custom-ok-cleal:focus {
  border-radius: 20px;
  font-size: 12px;
  height: 29px;
  opacity: 1;
  background: none;
  background-color: none;
  border: 1px solid #ed2616;
  color: #ed2616;
}
.loadMore {
  font-size: 12px;
  color: #999999;
  margin-top: 10px;
  cursor: pointer;
}
.loadMoreh {
  color: blue;
}

.custom-price {
  color: #ed2616;
  font-weight: 600;
  margin-top: 2px;
}
.yqtext {
  font-size: 12px;
  line-height: 37px;
  margin-right: 10px;
}
.imgdialog-order {
  border-radius: 10px;
  font-family: "PingFangSC-Regular";
}
.imgheader-order {
  font-weight: bold;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
.imgbox-order {
  display: flex;
  justify-content: space-between;
  height: 640px;
}
.imgbox-order-left {
  width: 250px;
  border-right: 1px solid #eee;
}
.imgbox-order-right {
  height: 640px;
  width: 350px;
}

.login-input {
  border-radius: 4px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  width: 220px;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 29px;
  outline: none;
  color: #999;
}

.login-input:focus {
  outline: none;
  border: 1px solid #d8d8d8;
}

.login-input::placeholder {
  color: #999999;
}
.action-item {
  margin-top: 16px;
  margin-left: 0px;
  margin-right: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
.seach-button {
  background-image: url('~@/assets/seach.png');
  background-size: 100%;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 6px;
  left: 195px;
  cursor: pointer;
}
.z-tabs {
  display: flex;
  justify-content: flex-start;
}
.z-tab {
  border-radius: 60px;
  opacity: 1;
  background: rgba(136, 136, 136, 0.1);
  font-family: PingFang SC;
  font-size: 11px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #222222;
  padding: 4px 10px;
  margin-right: 10px;
  cursor: pointer;
}
.z-tab-active {
  border-radius: 60px;
  opacity: 1;
  background: rgba(237, 38, 22, 0.1);
  font-family: PingFang SC;
  font-size: 11px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ed2616;
  padding: 4px 10px;
  margin-right: 10px;
  cursor: pointer;
}
.imbox-list {
  height: 540px;
  overflow-y: auto;
}
.plist-item {
  background: #f7f7f7;
  border-radius: 4px;
  height: 80px;
  margin-right: 16px;
  margin-top: 16px;
  padding: 10px;
  display: flex;
}
.item-image {
  width: 60px;
  height: 60px;
}
.item-image img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
.item-body {
  margin-left: 10px;
  width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item-body-name {
  font-size: 12px;
}
.item-body-desc {
  display: flex;
  justify-content: space-between;
}
.item-body-amount {
  font-size: 12px;
}
.item-body-button {
  font-size: 12px;
  border-radius: 60px;
  opacity: 1;
  background: rgba(136, 136, 136, 0.1);
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #fff;
  padding: 4px 10px;
  cursor: pointer;
}
.item-body-button-active {
  font-size: 12px;
  border-radius: 60px;
  opacity: 1;
  background: rgba(237, 38, 22, 0.1);
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  color: #ed2616;
  padding: 4px 10px;
  cursor: pointer;
}
.right-title {
  height: 50px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
}
.right-title-action {
  color: #ed2616;
}
.select-list {
  height: 560px;
}
.right-foot {
  height: 50px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.right-foot-num {
  font-size: 12px;
  margin-top: 10px;
}
.right-foot-amount {
  font-size: 12px;
  margin-top: 10px;
  margin-right: 80px;
}
.right-foot-button {
  width: 80px;
  height: 36px;
  opacity: 1;
  background: #ed2616;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  line-height: 36px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
}
.select-item {
  margin-left: 10px;
  margin-top: 10px;
}
.select-image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-left: 10px;
}
.select-image img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.select-image-icon {
  margin-top: 40px;
}
.box-select {
  border: none;
  width: 120px;
  outline: none;
}
.box-select::focus {
  border: none;
  width: 80px;
}
.box-input {
  border: 1px solid #eee;
  width: 50px;
  outline: none;
  text-align: center;
}
.box-input::focus {
  border: none;
  width: 80px;
}
.context-menu {
  position: fixed;
  background-color: #fff;
  list-style-type: none;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 999;
}
.context-menu li {
  padding: 4px 12px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  color: #222;
  border-radius: 5px;
  background-color: #fff;
}
.context-menu li:hover {
  background: rgba(237, 38, 22, 0.05);
}
.forward-plant {
  background-color: #fff;
  border-top: 1px solid #eee;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.forward-item {
  margin-top: 20px;
  margin-right: 72px;
}
.z-forward-pc {
  width: 44px;

  height: 44px;
}
.h-forward-pc {
  width: 44px;
  height: 44px;
}
.down-pc {
  width: 44px;
  height: 44px;
}
.close-pc {
  width: 16px;
  height: 16px;
}
.forward-txt {
  font-size: 14px;
  color: #222;
  margin-top: 8px;
  align-items: center;
}
.quote-plant {
  position: absolute;
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  border-left: 4px solid #d8d8d8;
  align-items: center;
  padding-left: 4px;
  padding-right: 10px;
}
.quote-text {
  margin-left: 8px;
  text-align: left;
  font-size: 12px;
  color: #666;
  padding-top:8px ;
  padding-bottom: 8px;
  font-family: "PingFang SC"
}
.quote-body {
  padding-left: 8px;
  text-align: left;
  font-size: 12px;
  color: #666;
  border-left: 4px solid #d8d8d8;
  margin-bottom: 8px;
}
.forward-dailog {
  border-radius: 10px;
  display: flex;
}
.forward-left {
  width: 216px;
  background-color: #fff;
  border-right: 1px solid #eee;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.forward-search {
  border-radius: 5px;
  background-color: #eee;
  margin-left: 16px;
  margin-right: 16px;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
}
.forward-search-icon {
  width: 15px;
  height: 15px;
}
.forward-search-input {
  margin-left: 6px;
  color: #9c9c9c;
  font-size: 12px;
  border: none;
  background-color: #eee;
  outline: none;
  width: 180px;
}

.forward-desc {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  color: #444;
  font-size: 12px;
}
.forward-session-list {
  margin-top: 8px;
  height: 300px;
  overflow-y: auto;
}
.forward-session-item {
  padding: 8px 18px 8px 18px;
  display: flex;
  align-items: center;
}
.forward-session-item:hover {
  background-color: rgba(237, 38, 22, 0.05);
}
.forward-session-icon {
  width: 14px;
  height: 14px;
}
.forward-session-avatar {
  width: 28px;
  height: 28px;
  border: 1px solid #979797;
  margin-left: 8px;
  border-radius: 50%;
}
.forward-session-nickname {
  color: #222;
  font-size: 10px;
  margin-left: 6px;
}
.forward-right {
  width: 256px;
  background-color: #fff;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.forward-right-title {
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forward-right-text {
  color: #222;
  font-size: 14px;
}
.forward-message {
  height: 30px;
  margin-left: 16px;
  margin-right: 16px;
  border-left: 4px solid #d8d8d8;
  padding-left: 12px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  margin-top: 16px;
}
.forward-message-text {
  color: #666;
  font-size: 12px;
}
.forward-message-input-bg {
  margin-left: 16px;
  margin-right: 16px;
  height: 29px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  padding: 0px 10px 0px 10px;
  background-color: #fff;
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.forward-message-input {
  color: #9c9c9c;
  font-size: 12px;
  border: none;
  background-color: #fff;
  outline: none;
  width: 180px;
}
.forward-message-buttons {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forward-message-button-calce {
  width: 108px;
  height: 31px;
  border-radius: 4px;
  background: #eeeeee;
  font-size: 12px;
  color: #222222;
  border: none;
}
.forward-message-button-ok {
  width: 108px;
  height: 31px;
  border-radius: 4px;
  background: #ed2616;
  font-size: 12px;
  color: #fffdfd;
  border: none;
}
/deep/ .ant-modal-content {
  border-radius: 10px;
}
.f-m-title {
  margin: 16px;
  margin-top: 0px;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
}
.f-m-t-text {
  font-size: 16px;
  color: #222;
  font-weight: 600;
}
.f-m-time {
  height: 30px;
  box-shadow: inset 0px 1px 0px 0px #eeeeee;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.f-m-list {
  height: 664px;
  overflow-y: auto;
  margin-left: 24px;
  margin-right: 24px;
}
.f-m-line {
  height: 46px;
  border-top: 1px solid #eee;
  margin-left: 24px;
  margin-right: 24px;
}
.f-m-item {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}
.f-m-i-title {
  display: flex;
}
.f-m-i-t-text {
  font-size: 13px;
  color: #666;
}
.f-m-i-t-time {
  font-size: 13px;
  color: #666;
  margin-left: 8px;
}
.f-m-i-body-text {
  font-size: 14px;
  color: #222;
}
.f-m-i-body-file {
  width: 243px;
  display: flex;
  border-radius: 10px;
  background: #fafafa;
  border: 1px solid rgba(34, 34, 34, 0.1);
  padding: 10px;
}
.f-m-i-body-f-left {
}
.f-m-i-body-f-l-text {
  color: #222;
  font-size: 16px;
}
.f-m-i-body-f-l-desc {
  color: #888;
  font-size: 12px;
  margin-top: 6px;
}
.f-m-i-body-f-img {
  width: 50px;
  height: 50px;
}
.f-m-i-body-img {
  max-width: 260px;
  border-radius: 5px;
}
.jiabian {
  padding: 4px 6px 4px 6px;
  font-size: 12px;
}

.note-right {
  width: 12px;
  height: 12px;
}
.note-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10rpx;
  border-top: 1px solid #eee;
  padding-top: 10px;
}
.note-button {
  color: #888;
  font-size: 10px;
}
.note-title {
  color: #222;
  font-weight: 600;
  font-size: 16px;
}
.note-content {
  color: #888;
  font-size: 12px;
  margin-top: 4px;
}

.mb {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
}
.demand-body{
  font-family: "PingFangSC-Regular";
  border: none;
  font-weight: 500;
}
.demand-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
}
.demand-title{
  width: 200px;
  font-size: 14px;
  color:#666;
}
.demand-desc{
  font-size: 14px;
  color:#222;
}
.demand-line{
  height: 1px;
  width: 100%;
  background-color:#eee;
  margin-bottom: 16px;
}
.demand-tab{
  display: flex;
  justify-items: flex-start;
  align-items: center;
  overflow-y: auto;

  height: 40px;
}
.demand-tab-item{
  font-size: 14px;
  color:#666;
  margin-right: 24px;
  position: relative;
}
.demand-tab .active {
  color:#ED2616;
}
.demand-tab .active:after {
  content: '';
  height: 3px;
  position: absolute;
  width: 100%;
  bottom: -8px;
  left: 0px;
  background: rgba(237, 38, 22, 1);
}
.demand-body{
  margin-top: 16px;
}
.demand-body-item{
  display: flex;
  align-items: center;
}
</style>
