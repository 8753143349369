<template>
  <div v-if="showMessage" @contextmenu.prevent="openMenu($event, message)">
    <!-- 文本消息 表情消息 超链接消息-->
    <div :class="message.belong ? 'text-body-my' : 'text-body'" v-if="message.extType == 0" v-html="getMessageContent(message)"></div>
    <!-- 引用消息 -->
    <div :class="message.belong ? 'text-body-my' : 'text-body'" v-if="message.extType == 1" @click.stop="goToQuoteMessage(message)">
      <div class="quote-body">
        <div>{{ getMemberName(message.messageExt) }}:</div>
        <div style="display: flex; align-items: center" v-if="message.messageExt.messageType == 'txt'" v-html="getMessageContent(message.messageExt)"></div>
        <div style="display: flex; align-items: center" v-if="message.messageExt.messageType == 'audio'">[语音]{{ message.messageExt.messageAudioLength }}"</div>
        <div style="display: flex; align-items: center" v-if="message.messageExt.messageType == 'img'">
          <img style="max-height: 56px" :src="message.messageExt.messageUrl" />
        </div>
        <div style="display: flex; align-items: center" v-if="message.messageExt.messageType == 'file'"><img style="max-height: 32px; width: 32rpx" :src="getFileIcon(message.messageExt)" />{{ message.messageExt.messageFileName }}</div>
        <div style="display: flex; align-items: center" v-if="message.messageExt.messageType == 'video'">
          <img style="max-height: 56px" :src="message.messageExt.videoUrl" />
        </div>
        <div style="display: flex; align-items: center" v-if="message.messageExt.messageType == 'custom'">{{ getCustomType(message.messageExt) }}</div>
      </div>
      {{ message.messageMsg }}
    </div>
    <!-- 转发消息 -->
    <div :class="message.belong ? 'text-body-my' : 'text-body'" v-if="message.extType == 2" @click.stop="openForwardMessageListDailogByList(message)">
      <div style="font-size: 14px">{{ message.messageMsg }}</div>
      <div style="margin-top: 8px" v-for="extItem in message.messageExt" :key="extItem.id">
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'txt' && (extItem.extType == 0 || extItem.extType == 2)">
          {{ getMemberName(extItem) }}:
          <div v-html="getMessageContent(extItem)"></div>
        </div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'audio'">{{ getMemberName(extItem) }}:[语音]{{ extItem.messageAudioLength }}"</div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'img'">{{ getMemberName(extItem) }}:<img style="max-height: 56px" :src="extItem.messageUrl" /></div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'file'">{{ getMemberName(extItem) }}:<img style="max-height: 32px; width: 32rpx" :src="getFileIcon(extItem.messageFileName)" />{{ extItem.messageFileName }}</div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'video'">{{ getMemberName(extItem) }}:<img style="max-height: 56px" :src="extItem.videoUrl" /></div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'custom'">{{ getMemberName(extItem) }}:{{ getCustomType(extItem) }}</div>
        <div style="display: flex; align-items: center; color: #888; margin-top: 2px" v-if="extItem.messageType == 'txt' && extItem.extType == 1">
          <div class="quote-body">
            <div>{{ getMemberName(extItem.messageExt) }}:</div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'txt'" v-html="getMessageContent(extItem.messageExt)"></div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'audio'">[语音]{{ extItem.messageExt.messageAudioLength }}"</div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'img'">
              <img style="max-height: 56px" :src="extItem.messageExt.messageUrl" />
            </div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'file'"><img style="max-height: 32px; width: 32rpx" :src="getFileIcon(extItem.messageExt)" />{{ extItem.messageExt.messageFileName }}</div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'video'">
              <img style="max-height: 56px" :src="extItem.messageExt.videoUrl" />
            </div>
            <div style="display: flex; align-items: center" v-if="extItem.messageExt.messageType == 'custom'">{{ getCustomType(extItem.messageExt) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emMap from '@/utils/em'
export default {
  name: 'textMessage',
  data() {
    return {
      showMessage: false,
    }
  },
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.initData(this.message)
    this.showMessage = true
  },
  methods: {
    initData(m) {
        if(m.extType == 2){
            if( typeof m.messageExt == "string"){
                m.messageExt = JSON.parse(m.messageExt).exts
                m.messageExt.forEach(e=>{
                    this.initData(e)
                })
            }
        }
        if(m.extType == 1){
            if( typeof m.messageExt == "string"){
                m.messageExt = JSON.parse(m.messageExt)
                this.initData(m.messageExt)
            }
        }
        if( typeof m.messageCustomExt == "string"){
            m.messageCustomExt = JSON.parse(m.messageCustomExt)
        }
    },
    openForwardMessageListDailogByList(item){
      this.$emit("openForwardMessageListDailogByList",item)
    },
    goToQuoteMessage(item){
      this.$emit("goToQuoteMessage",item)
    },
    openMenu(event,item){
        console.log("右键事件最底层")
        this.$emit('openMenu',{event:event,item:item})
    },
    getMemberName(body) {
      let nameText = ''
      body.members.forEach(e => {
        if (body.messageFrom == e.id) {
          nameText = e.nickname
        }
      })
      return nameText
    },
    getFileIcon(item) {
      let fileExtension = item.substring(item.lastIndexOf('.') + 1)
      if (fileExtension == 'zip' || fileExtension == 'ZIP') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/zip.png'
      }
      if (fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'DOC' || fileExtension == 'DOCX') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/word.png'
      }
      if (fileExtension == 'xls' || fileExtension == 'xlsx' || fileExtension == 'XLS' || fileExtension == 'XLSX') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/xls.png'
      }
      if (fileExtension == 'txt' || fileExtension == 'TXT') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/txt.png'
      }
      if (fileExtension == 'pdf' || fileExtension == 'PDF') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/pdf.png'
      }
      if (fileExtension == 'ppt' || fileExtension == 'pptx' || fileExtension == 'PPT' || fileExtension == 'PPTX') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/ppt.png'
      }
      if (fileExtension == 'dwg' || fileExtension == 'dxf' || fileExtension == 'DWG' || fileExtension == 'DXF') {
        return 'https://cdn.binliyoujia.com/static/message/message-img/cad.png'
      }
    },
    getCustomType(e) {
      if (e.messageCustomExt.type == 'designerOrder') {
        if (e.messageCustomExt.status == 100) {
          return '[已关闭订单]'
        }
        if (e.messageCustomExt.status == 0) {
          return '[未支付订单]'
        }
        if (e.messageCustomExt.status == 10) {
          return '[已支付订单]'
        }
        if (e.messageCustomExt.status == 20) {
          return '[待确认需求订单]'
        }
        if (e.messageCustomExt.status == 30) {
          return '[制图中订单]'
        }
        if (e.messageCustomExt.status == 40) {
          return '[待确认图纸订单]'
        }
        if (e.messageCustomExt.status == 50) {
          return '[已完成订单]'
        }
      }
      if (e.messageCustomExt.type == 'selectOrder') {
        return '[商品价格改好了，可直接付款哦]'
      }
      if (e.messageCustomExt.type == 'demandOrder') {
        return '[我发送了一个设计需求，请尽快查看并回复哦~]'
      }
      if (e.messageCustomExt.type == 'designerFixOrder') {
        return '[补差订单]'
      }
      if (e.messageCustomExt.type == 'renderProduct') {
        return '[效果图]'
      }

      if (e.messageCustomExt.type == 'submitChangeSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'demandSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'payFixSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'paySuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'refundOrder') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'refundChangeOrder') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'refundOrderSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'refundChangeOrderSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'subimtSuccess') {
        return '[' + e.messageCustomExt.title + ']'
      }
      if (e.messageCustomExt.type == 'designerChangeOrder') {
        if (e.messageCustomExt.status == 60) {
          return '[改图待确认需求]'
        }
        if (e.messageCustomExt.status == 70) {
          return '[改图制图中]'
        }
        if (e.messageCustomExt.status == 50) {
          return '[已完成订单]'
        }
        if (e.messageCustomExt.status == 0) {
          return '[改图待确认需求(未支付)]'
        }
      }
    },
    getMessageContent(body) {
      let msg = body.messageMsg
      if (msg.indexOf('http') >= 0) {
        //网址
        return '<a herf="' + msg + '">' + msg + '</a>'
      }
      //表情包含替换
      for (const key in emMap) {
        if (msg.indexOf(key) >= 0) {
          msg = msg.replaceAll(key, '<image style="width:18px;height:18px;" src="' + ('https://cdn.binliyoujia.com/static/im/faces/' + emMap[key]) + '"/>')
        }
      }
      return msg
    },
  },
}
</script>
<style scoped>
.text-body {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  background: #eeeeee;
  margin-top: 6px;
}
.text-body-my {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  background: #fff3e8;
  margin-top: 6px;
}
.text-body p {
  margin-bottom: 0px !important;
}
.text-body-my p {
  margin-bottom: 0px !important;
}
.quote-body {
  padding-left: 8px;
  text-align: left;
  font-size: 12px;
  color: #666;
  border-left: 4px solid #d8d8d8;
  margin-bottom: 8px;
}
</style>