<template >
    <div v-if="showMessage" @contextmenu.prevent="openMenu($event, message)">
        <div :class="message.belong ? 'text-body-my' : 'text-body'">
            <previewImage class="image-box" :src="message.messageUrl" />
        </div>
        <!-- <div style="margin-top: 10px; font-size: 12px; color:#ed2616;" ><a :href="message.messageUrl+'?response-content-type=application/octet-stream'" download >点击下载</a></div> -->
        <div class="down-link-cs" v-down="message">点击下载</div>
        
    </div>
</template>
<script>
import previewImage from '@/components/elimage.vue'
export default {
    name: 'ImgMessage',
    components: {
        previewImage
    },
    props:{ 
        message: {
            type: Object,
            default: null
        }
    },
    data() {
      return {
        showMessage:false
      }
    },
    mounted () {
        this.showMessage = true
    },
    methods:{
        openMenu(event,item){
            console.log("右键事件最底层")
            this.$emit('openMenu',{event:event,item:item})
        },
    }
}
</script>
<style scoped>
.text-body {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  border-radius: 0px 10px 10px 10px;
  padding: 10px;
  background: #eeeeee;
  margin-top: 6px;
}
.text-body-my {
  max-width: 100%;
  opacity: 1;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #222222;
  text-align: left;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  background: #fff3e8;
  margin-top: 6px;
}
.text-body p {
  margin-bottom: 0px !important;
}
.text-body-my p {
  margin-bottom: 0px !important;
}
.image-box {
  width: 100px;
}
.down-link-cs{
  margin-top: 10px; font-size: 12px; color:#ed2616;
}
</style>